body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


.ant-table th {
	font-size: 12px;  /* You can adjust the size as needed */
  }

/* DataDisplay.css */
.data-container {
	background-color: #f4f4f9;
	padding: 20px;
	border-radius: 8px;
	width: 80%;
	max-width: 800px;
	margin: 0 auto;
	margin-top: 0px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	/* This creates two equal columns */
	gap: 20px;
	/* Adds space between the columns */
}

.shadow {
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
}

.data-item {
	display: flex;
	justify-content: space-between;
	padding: 12px;
	background-color: #ffffff;
	border-radius: 6px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.label {
	font-weight: 500;
	color: #555;
}

.value {
	color: #1d72b8;
	font-size: 1.2rem;
}

.loading {
	text-align: center;
	font-size: 1.5rem;
	color: #555;
	grid-column: span 2;
	/* Makes the loading text span across both columns */
}

.error {
	text-align: center;
	font-size: 1.5rem;
	color: #e74c3c;
	grid-column: span 2;
	/* Makes the error message span across both columns */
}